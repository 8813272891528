import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Imagesir from "./../images/downloads/element5.jpg"
import UR from "../components/ur"
import PSF from "../components/psf"
import REB from "../components/reb"
import CEN from "../components/cen"
import RDB from "../components/rdb"
import HEC from "../components/hec"
import Visitrwanda from "../components/visitrwanda"
import SEO from "../components/seo"
import Header from "../components/headerhomepage"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Header />
    <section className="section bg-light pb-5 py-lg-7 pb-lg-10">
      <div className="container text-center text-lg-left pt-5">
        <div className="row row-grid mx-lg-n5">
          <div className="col-md-4 mb-5 mb-lg-0 px-lg-5">
            <h5 className="font-weight-bold text-primary-alt">Invest in Rwanda</h5>
            <p className="mt-2 mb-0 lh-190">
              Rwanda's high economic growth prospects are supported by its
              favorable business environment and infrastructure.
            </p>
          </div>
          <div className="col-md-4 mb-5 mb-lg-0 px-lg-5">
            <h5 className="font-weight-bold text-primary-alt">Partner with us</h5>
            <p className="mt-2 mb-0 lh-190">
              We help you to reach your audience, gain access to your network of
              expertise and achive your investment goals.
            </p>
          </div>
          <div className="col-md-4 mb-5 mb-lg-0 px-lg-5">
            <h5 className="font-weight-bold text-primary-alt">Exchange programs</h5>
            <p className="mt-2 mb-0 lh-190">
              Study in Rwanda invites you to Rwanda! Make international friends,
              taste our exotic cuisines, enjoy our unique traditional dances and
              explore new scenaries!
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section py-8 px-8 pb-9">
      <div className="container-fluid">
        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-lg-8 order-lg-2">
              <h5 className="h3 font-weight-bold text-primary">Why study in Rwanda?</h5>
              <p className=" my-4">
              Study in Rwanda is a program that facilitates student recruitment to students who wish to raise their academic profile in public and private institutions in Rwanda. Study in Rwanda program not only helps students meet their academic demands, we also collaborate with institutions that wish to invest in our education system.
              </p>
              <p className=" my-4">
              The program is also designed to promote the education sector in Rwanda as well as international universities and research centers that wish to establish or expand their campuses in Rwanda.
              </p>
              <p className=" my-4">
              Our program seeks to establish good relationships with foreign investors in Education. We are dedicated to attract foreign investment in the education sector to fulfill the rationale of its existence.
              </p>
            </div>
            <div className="col-lg-4 order-lg-1">
              <div className="card mb-0 mr-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={Imagesir}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-lg-7">
              <h5 className="h3 font-weight-bold text-primary"> What next after books?</h5>
              <p className="my-4">
                Studying in a new environment is not only an interesting
                experience but outright life changing. After a long day at
                school, there is always something else to do and new friends to
                meet!
              </p>
              <p className="my-4">
                Study in Rwanda has a thought out plan to ensure our students
                have extra activities to do. Students don’t have to worry about
                being alone in the hostel after classes. We have a student
                community that helps learners travel all over Rwanda in the
                company of other Students from Rwanda and all over the world.
                Our student community also participates in engaging activities
                such as fashion week, Umuganda, car free day etc.
              </p>
              <p className="my-4">
                Study in Rwanda also offers volunteer platforms and paid
                internships for our international students to help build their
                profile and work experience while studying.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="card mb-0 ml-lg-5">
                <div className="card-body p-2">
                  <img
                    alt="Image placeholder"
                    src={require("./../images/downloads/achievement-agreement-arms-1068523.jpg")}
                    className="img-fluid shadow rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section pt-4 px-8 pb-4">
      <div className="container-fluid">
        <div className="pt-4">
          <div className="row row-grid justify-content-between">
            <div className="col-lg-4 order-lg-2">
              <h5 className="h3 font-weight-bold text-primary">
                Why the education sector in Rwanda?
              </h5>
              <p className=" my-4">
                To combat educational challenges in Africa and make Rwanda the
                best study destination in Africa.
              </p>
            </div>
            <div className="col-lg-7 order-lg-1">
              <h5 className="h3 font-weight-bold text-primary">
                Why Rwanda amongst all other countries in Africa?
              </h5>
              <p className="my-4">
                Study in Rwanda operates by supporting the Government vision. We
                hands with government bodies such as the Ministry of Education.
                Study in Rwanda supports the ministry’s implementation plan and
                support towards local institutions to raise education standards
                to international level. This way Rwanda can host more
                international students.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section clients">
      <div className="container-fluid px-lg-2">
        <div className="pb-4 px-lg-8">
          <div className="client-group row justify-content-around align-items-center text-center">
            <div className="client col-lg col-md-6 col-6 py-2">
              <a href="https://ur.ac.rw/" target="_blank" rel="noopener noreferrer">
                <UR />
              </a>
            </div>
            <div className="client col-lg col-md-6 col-6 py-2">
              <a href="https://reb.rw/" target="_blank" rel="noopener noreferrer">
                <REB />
              </a>
            </div>
            <div className="client col-lg col-md-6 col-6 py-2">
              <a href="https://rdb.rw/" target="_blank" rel="noopener noreferrer">
                <RDB />
              </a>
            </div>
            <div className="client col-lg col-md-6 col-6 py-2">
              <a href="https://cenrwanda.org" target="_blank" rel="noopener noreferrer">
                <CEN />
              </a>
            </div>
            <div className="client col-lg col-md-6 col-6 py-2">
              <a href="https://www.psf.org.rw/" target="_blank" rel="noopener noreferrer">
                <PSF />
              </a>
            </div>
            <div className="client col-lg col-md-6 col-6 py-2">
              <a href="https://hec.gov.rw" target="_blank" rel="noopener noreferrer">
                <HEC />           
              </a>   
            </div>
            <div className="client col-lg col-md-6 col-6 py-2">
              <a href="https://www.visitrwanda.com/" target="_blank" rel="noopener noreferrer">
                <Visitrwanda />          
              </a>    
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
